import "./styles.css";

// import PartySocket from "partysocket";
// import { WebSocket } from "partysocket";

declare const PARTYKIT_HOST: string;

// Let's append all the messages we get into this DOM element
const output = document.getElementById("app") as HTMLDivElement;

// Helper function to add a new line to the DOM
function add(text: string) {
  output.appendChild(document.createTextNode(text));
  output.appendChild(document.createElement("br"));
}

const room =
  new URLSearchParams(window.location.search).get("room") ?? "my-new-room";
// A PartySocket is like a WebSocket, except it's a bit more magical.
// It handles reconnection logic, buffering messages while it's offline, and more.
// const conn = new PartySocket({
//   host: PARTYKIT_HOST,
//   room: "my-new-room",
// });

// const conn = new WebSocket("ws://127.0.0.1:49376");
const conn = new WebSocket("wss://jevakallio-wrangler-raw.partykit.dev");

// You can even start sending messages before the connection is open!
conn.addEventListener("message", (event) => {
  add(`Received -> ${event.data}`);
});

// Let's listen for when the connection opens
// And send a ping every 2 seconds right after
conn.addEventListener("open", () => {
  add(`Connected to ${room}!`);
});
